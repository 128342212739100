<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div class="breadcrumb-title pe-3">Blog</div>
          <div class="ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0 p-0">
                <li class="breadcrumb-item">
                  <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Update Blog
                </li>
              </ol>
            </nav>
          </div>
          <div class="ms-auto"></div>
        </div>
        <hr />
        <form>
          <div class="card">
            <div class="card-body p-4">
              <h5 class="card-title">Update Blog</h5>
              <hr />
              <div class="form-body mt-4">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="border border-3 p-4 rounded mb-3">
                      <div class="mb-3">
                        <label for="inputProductTitle" class="form-label">Name</label>
                        <input type="email" class="form-control" id="inputProductTitle" placeholder="Blog name"
                          v-model="singleBlog.name" />
                      </div>
                      <div class="mb-3">
                        <label for="inputProductTitle" class="form-label">Author</label>
                        <input type="text" class="form-control" id="inputProductTitle" placeholder="Author name"
                          v-model="singleBlog.author" />
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Description</label>
                        <ckeditor :editor="editor" v-model="singleBlog.description" :config="editorConfig"></ckeditor>
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Publish Date</label>
                        <input type="date" v-model="singleBlog.publishDate" class="form-control input-group">
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                      <div class="border border-3 p-4 rounded">
                        <div class="mb-3">
                          <label class="form-label">Image: <strong>[Preferred Image Size: 1440X2114, Max Upload Limit:
                              1MB]</strong></label>
                          <input class="form-control mb-2" type="file" name="featured_image" id="featuredImage"
                            ref="image" aria-describedby="imageHelp" accept="image/*" @change="uploadBlogPhoto" />
                          <img v-if="previewImage" :src="previewImage" height="80"/>
                          <img v-else :src="singleBlog.image" height="80" />
                        </div>
                        <div class="mb-3">
                          <label for="inputUrlSlug" class="form-label">Url Slug</label>
                          <input type="text" class="form-control" id="inputUrlSlug" placeholder="url slug"
                            v-model="singleBlog.urlSlug" disabled/>
                        </div>
                        
                        <div>
                          <div class="d-grid">
                            <button type="button" @click="updateBlogPost" class="btn btn-primary">
                              Update Blog
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <!--end row-->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script src='../js/update-blog-post.js'></script>