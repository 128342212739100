<template>
    <div class="modal fade" id="addDiscountModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <form @submit.prevent="">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Add Discount</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <label class="form-label">Discount Type:</label>
                            <select class="form-select mb-1" v-model="discountType" @change="onDiscountType($event)">
                                <option value="Amount">Amount</option>
                                <option value="Percentage">Percentage</option>
                            </select>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label">Amount/Percentage:</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic_addon1">{{ discountSymbol }}</span>
                                </div>
                                <input type="number" min="0" v-model="discountAmount" class="form-control" aria-describedby="basic-addon1">
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" @click="update()">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
  
<script src="../js/add-discount-modal.js"></script>
  