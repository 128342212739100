<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Products</p>
                  <h4 class="my-1">{{ totalProducts }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <i class="bx bxs-wallet"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Customers</p>
                  <h4 class="my-1">{{ totalCustomers }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-warning text-warning ms-auto"
                >
                  <i class="bx bxs-group"></i>
                </div>
              </div>
              <!-- <div id="chart2"></div> -->
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Blogs</p>
                  <h4 class="my-1">{{ totalBlogs }}</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <!-- <i class="bx bx-user-circle"></i> -->
                  <i class="bx bxl-blogger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Order</p>
                  <h4 class="my-1">{{ totalOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <span
                    class="iconify"
                    data-icon="akar-icons:shipping-box-v2"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Pending</p>
                  <h4 class="my-1">{{ totalPendingOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <span class="iconify" data-icon="fa6-solid:truck-fast"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Delivered</p>
                  <h4 class="my-1">{{ totalDeliveredOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <span class="iconify" data-icon="bxs:truck"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Cancelled</p>
                  <h4 class="my-1">{{ totalCancelledOrders }}</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <span
                    class="iconify"
                    data-icon="fluent:calendar-cancel-16-filled"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mx-auto">
          <!-- <h6 class="mb-0 text-uppercase">Top Category</h6> -->
          <!-- <hr/> -->
          <div class="card">
            <div class="card-body">
              <div class="chart-container1">
                <canvas
                  id="categoryChart"
                  style="position: relative; height: 80vh; width: 80vw"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mx-auto">
          <!-- <h6 class="mb-0 text-uppercase">Bar Chart</h6> -->
          <!-- <hr/> -->
          <div class="card">
            <div class="card-body">
              <div class="chart-container1">
                <!-- <canvas id="chart2"></canvas> -->
                <canvas
                  id="myChart"
                  style="position: relative; height: 80vh; width: 80vw"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end row-->

      <!-- Orders History -->
      <div class="row">
        <div class="col-xl-12 d-flex">
          <div class="card radius-10 w-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <h5 class="mb-1">Today's Order History</h5>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive mt-4">
                    <table
                      class="table table-striped table-bordered"
                      id="example"
                    >
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>Order Id</th>
                          <th>Total Price</th>
                          <th>Total Quantity</th>
                          <th>Payment Status</th>
                          <th>Shipping Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in todaysOrders" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              @click="
                                $router.push('/order-details/' + data.orderId)
                              "
                              >{{ data.orderId }}</a
                            >
                          </td>
                          <td>{{ data.total }}</td>
                          <td>{{ data.totalQuantity }}</td>
                          <td>{{ data.paymentStatus }}</td>
                          <td>{{ data.status }}</td>
                          <td>
                            <div class="d-flex order-actions">
                              <a
                                href="javascript:void(0)"
                                @click="
                                  $router.push('/order-details/' + data.orderId)
                                "
                                class="edit-button"
                                ><i class="bx bxs-show text-white"></i
                              ></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <LinkShortcut />
        </div>
      </div>
      <!-- Product History -->
      <div class="row">
        <div class="col-xl-12 d-flex">
          <div class="card radius-10 w-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <h5 class="mb-1">Today's Product History</h5>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive mt-4">
                    <table
                      class="table table-striped table-bordered"
                      id="example"
                    >
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>Name</th>
                          <th>Brand</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in todayProducts" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ data.name }}</td>
                          <td>{{ data.brandName }}</td>
                          <td>{{ $filters.formatDate(data.createdAt) }}</td>
                          <td>
                            <div class="d-flex order-actions">
                              <a
                                href="javascript:void(0);"
                                @click="
                                  $router.push('/update-product/' + data.id)
                                "
                                class="edit-button"
                                ><i class="bx bxs-show text-white"></i
                              ></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <LinkShortcut />
        </div>
      </div>
      <!-- <div class="row mt-5">
        <div class="col-xl-12 d-flex">
            <div class="card radius-10 w-100">
                <div class="card-body">
                    <h5 class="card-title mb-0 pb-0">Quick Links</h5>
                    <hr>
                    <div class="d-flex justify-content-around align-items-center">
                        <div class="btn-group m-2">
                            <a href='https://hymanfashion-admin.1space.co/category'  class="btn  btn-primary rounded-circle m-1 d-flex flex-column justify-content-center" style="height: 120px; width:120px;" target="_blank">
                                <i class="fa-solid fa-link mt-1"></i> <br>Category
                            </a>
                        </div>
                        <div class="btn-group m-2">
                            <a href='https://goldengaljewelry.com/shop/color/Red'  class="btn  btn-primary rounded-circle m-1 d-flex flex-column justify-content-center" style="height: 120px; width:120px;" target="_blank">
                                <i class="fa-solid fa-link mt-1"></i><br>Color 
                            </a>
                        </div>
                        <div class="btn-group m-2">
                            <a href='https://goldengaljewelry.com/shop/size/M'  class="btn  btn-primary rounded-circle m-1 d-flex flex-column justify-content-center" style="height: 120px; width:120px;" target="_blank">
                                <i class="fa-solid fa-link mt-1"></i> <br>Size
                            </a>
                        </div>
                        <div class="btn-group m-2">
                            <a href='https://goldengaljewelry.com/shop/tag/Ring'  class="btn  btn-primary rounded-circle m-1 d-flex flex-column justify-content-center" style="height: 120px; width:120px;" target="_blank">
                                <i class="fa-solid fa-link mt-1"></i> <br>Sale
                            </a>
                        </div>
                        <div class="btn-group m-2">
                            <a href='https://goldengaljewelry.com/shop/highlight/Special Collection'  class="btn  btn-primary rounded-circle m-1 d-flex flex-column justify-content-center" style="height: 120px; width:120px;" target="_blank">
                                <i class="fa-solid fa-link mt-1"></i> <br>Care
                            </a>
                        </div>
                        <div class="btn-group m-2">
                            <a href='https://goldengaljewelry.com/shop/material/Diamond'  class="btn  btn-primary rounded-circle m-1 d-flex flex-column justify-content-center" style="height: 120px; width:120px;" target="_blank">
                                <i class="fa-solid fa-link mt-1"></i> <br>Offer
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>