import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import BlogPost from "../components/Blog/BlogPost/template/BlogPost";
import AddBlogPost from "../components/Blog/BlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/Blog/BlogPost/template/UpdateBlogPost";
import Profile from "../components/profile/template/Profile";

//user
import Admin from "../components/user/template/Admin";
import Customer from "../components/user/template/Customer";

//CMS
import AboutUsSectionOneCMS from "../components/CMS/about-us/SectionOneCMS/template/SectionOneCMS";
import AboutUsSectionTwoCMS from "../components/CMS/about-us/SectionTwoCMS/template/SectionTwoCMS";
import OurMissionSectionCMS from "../components/CMS/about-us/OurMissionSectionCMS/template/OurMissionSectionCMS";
import OurCommunitySectionCMS from "../components/CMS/about-us/OurCommunitySectionCMS/template/OurCommunitySectionCMS";
import OpenSourceCommunitySectionCMS from "../components/CMS/about-us/OpenSourceCommunitySectionCMS/template/OpenSourceCommunitySectionCMS";
import ContactUsCMS from "../components/CMS/contact-us/template/ContactUsCMS";
import LoginPageCMS from "../components/CMS/auth/LoginPageCMS/template/LoginPageCMS";
import RegisterPageCMS from "../components/CMS/auth/RegisterPageCMS/template/RegisterPageCMS";
import ResetPasswordPageCMS from "../components/CMS/auth/ResetPasswordPageCMS/template/ResetPasswordPageCMS";
import PrivacyPageCMS from "../components/CMS/privacy-policy/template/PrivacyPageCMS";
import TermsPageCMS from "../components/CMS/terms-and-conditions/template/TermsPageCMS";
import FooterBannerCMS from "../components/CMS/header-footer/FooterBannerCMS/template/FooterBannerCMS";
import HeaderFooterCMS from "../components/CMS/header-footer/HeaderFooterCMS/template/HeaderFooterCMS";
import FaqPageCMS from "../components/CMS/faq/PageCMS/template/FaqPageCMS";
import HeroSectionSlider from "../components/CMS/home/hero-section/template/HeroSectionSlider";
import AboutSectionCMS from "../components/CMS/home/AboutSectionCMS/template/AboutSectionCMS";
import ShippingMethods from "../components/shipping-methods/template/ShippingMethods.vue";
import BannerSectionCMS from "../components/CMS/home/BannerSectionCMS/template/BannerSectionCMS";
import OurProductSectionCMS from "../components/CMS/home/OurProductSectionCMS/template/OurProductSectionCMS";
import DiscoverSectionCMS from "../components/CMS/home/DiscoverSectionCMS/template/DiscoverSectionCMS";
import PackageSectionCMS from "../components/CMS/home/PackageSectionCMS/template/PackageSectionCMS";
import BlogSectionCMS from "../components/CMS/home/BlogSectionCMS/template/BlogSectionCMS";
import TestimonialSectionCMS from "../components/CMS/home/TestimonialSectionCMS/template/TestimonialSectionCMS";
import InstagramSectionCMS from "../components/CMS/home/InstagramSectionCMS/template/InstagramSectionCMS";
import PromotionSection from "../components/CMS/home/PromotionSection/template/PromotionSection";
import AboutSection from "../components/CMS/home/AboutSection/template/AboutSection";
import PurchaseAndDeliveryCMS from "../components/CMS/product/purchase-and-delivery-cms/template/PurchaseAndDeliveryCMS";
import RefundPolicyCMS from "../components/CMS/product/refund-policy-cms/template/RefundPolicyCMS";
import PurchaseDeliveryPolicy from "../components/CMS/purchase-delivery-policy/template/PurchaseDeliveryPolicy";
import Package from "../components/CMS/home/Packages/template/Crud";
import AddNewPackage from "../components/CMS/home/Packages/template/AddNewPackage";
import EditNewPackage from "../components/CMS/home/Packages/template/EditPackage";
import PageTitleCMS from "../components/CMS/PageTitleCms/template/PageTitleCMS"
//Contact
import ContactMessageList from "../components/Contact/template/ContactMessageList";
import Newsletter from "../components/Newsletter/template/Newsletter";

//FAQ
import FAQ from "../components/CMS/faq/Faq/template/Faq";

//Social Links
import SocialLinks from "../components/CMS/social-links/template/SocialLinks";

//Product
import BrandList from "../components/product/brand/template/Brand";
import ProductColor from "../components/product/product-color/template/ProductColor";
import ProductOffer from "../components/product/product-offer/template/ProductOffer";
import AddProduct from "../components/product/addProduct/template/AddProduct";
import UpdateProduct from "../components/product/updateProduct/template/UpdateProduct";
import ProductList from "../components/product/productList/template/Product";
import ProductBulkUpload from "../components/product/ProductBulkUpload/template/ProductBulkUpload";
import ProductStockIn from "../components/product/ProductStockIn/template/ProductStockIn";


//Testimonial
import Testimonial from "../components/Testimonial/template/Testimonial";

//Instagram
import InstagramService from "../components/InstagramSection/template/InstagramSection";

//Tax
import SalesTax from "../components/SalesTax/template/SalesTax";
import UnapprovedTaxExempt from "../components/tax-exempt/template/UnapprovedTaxExempt";
import ApprovedTaxExempt from "../components/tax-exempt/template/ApprovedTaxExempt";
import RejectedTaxExempt from "../components/tax-exempt/template/RejectedTaxExempt";

//Shipping Settings
import Ups from "../components/ShippingSettings/Ups/template/Ups";
import ShippingCMS from "../components/CMS/ShippingCMS/template/ShippingCMS";

//Payment
import PaymentConfiguration from "../components/Payment/payment-configuration/template/PaymentConfiguration";
import PaymentList from "../components/Payment/PaymentList/template/PaymentList.vue";
//Coupon
import Coupon from "../components/Coupon/template/Coupon";

//Order
import CreateOrder from "../components/Order/CreateOrder/template/CreateOrder";
import OrderList from "../components/Order/OrderList/template/OrderList";
import OrderDetails from "../components/Order/OrderDetails/template/OrderDetails";
// component UI
import HomeComponentUI from "../components/componentUI/Home/template/HomeComponentUI";


const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
	{ path: "/", redirect: { name: 'Home' } },
	{ path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard },

    //Blog
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },

    //User
    { path: "/admins", name: "Admin", component: Admin, beforeEnter: guard },
    { path: "/customers", name: "Customer", component: Customer, beforeEnter: guard },

    //CMS
    { path: "/about-us-section-one-cms", name: "AboutUsSectionOneCMS", component: AboutUsSectionOneCMS, beforeEnter: guard },
    { path: "/about-us-section-two-cms", name: "AboutUsSectionTwoCMS", component: AboutUsSectionTwoCMS, beforeEnter: guard },
    { path: "/our-mission-section-cms", name: "OurMissionSectionCMS", component: OurMissionSectionCMS, beforeEnter: guard },
    { path: "/our-community-section-cms", name: "OurCommunitySectionCMS", component: OurCommunitySectionCMS, beforeEnter: guard },
    { path: "/open-source-community-section-cms", name: "OpenSourceCommunitySectionCMS", component: OpenSourceCommunitySectionCMS, beforeEnter: guard },
    { path: "/contact-us-cms", name: "ContactUsCMS", component: ContactUsCMS, beforeEnter: guard },
    { path: "/login-page-cms", name: "LoginPageCMS", component: LoginPageCMS, beforeEnter: guard },
    { path: "/register-page-cms", name: "RegisterPageCMS", component: RegisterPageCMS, beforeEnter: guard },
    { path: "/reset-password-page-cms", name: "ResetPasswordPageCMS", component: ResetPasswordPageCMS, beforeEnter: guard },
    { path: "/privacy-page-cms", name: "PrivacyPageCMS", component: PrivacyPageCMS, beforeEnter: guard },
    { path: "/terms-page-cms", name: "TermsPageCMS", component: TermsPageCMS, beforeEnter: guard },
    { path: "/footer-banner-cms", name: "FooterBannerCMS", component: FooterBannerCMS, beforeEnter: guard },
    { path: "/header-footer-cms", name: "HeaderFooterCMS", component: HeaderFooterCMS, beforeEnter: guard },
    { path: "/faq-page-cms", name: "FaqPageCMS", component: FaqPageCMS, beforeEnter: guard },
    { path: "/hero-section-slider", name: "HeroSectionSlider", component: HeroSectionSlider, beforeEnter: guard },
    { path: "/home-about-section-cms", name: "AboutSectionCMS", component: AboutSectionCMS, beforeEnter: guard },
    { path: "/home-banner-section-cms", name: "BannerSectionCMS", component: BannerSectionCMS, beforeEnter: guard },
    { path: "/home-our-product-section-cms", name: "OurProductSectionCMS", component: OurProductSectionCMS, beforeEnter: guard },
    { path: "/home-discover-section-cms", name: "DiscoverSectionCMS", component: DiscoverSectionCMS, beforeEnter: guard },
    { path: "/home-package-section-cms", name: "PackageSectionCMS", component: PackageSectionCMS, beforeEnter: guard },
    { path: "/home-blog-section-cms", name: "BlogSectionCMS", component: BlogSectionCMS, beforeEnter: guard },
    { path: "/home-testimonial-section-cms", name: "TestimonialSectionCMS", component: TestimonialSectionCMS, beforeEnter: guard },
    { path: "/home-instagram-section-cms", name: "InstagramSectionCMS", component: InstagramSectionCMS, beforeEnter: guard },
    { path: "/home-promotion-section", name: "PromotionSection", component: PromotionSection, beforeEnter: guard },
    { path: "/home-about-section", name: "AboutSection", component: AboutSection, beforeEnter: guard },
    { path: "/purchase-and-delivery-cms", name: "PurchaseAndDeliveryCMS", component: PurchaseAndDeliveryCMS, beforeEnter: guard },
    { path: "/refund-policy-cms", name: "RefundPolicyCMS", component: RefundPolicyCMS, beforeEnter: guard },
    { path: '/Package', name: "Package", component: Package, beforeEnter: guard },
	{ path: '/add-new-package', name: "AddNewPackage", component: AddNewPackage, beforeEnter: guard },
	{ path: '/edit-package/:id', name: "EditNewPackage", component: EditNewPackage, beforeEnter: guard },
    { path: '/page-title-cms', name: "PageTitleCMS", component: PageTitleCMS, beforeEnter: guard },


    //Contact
    { path: "/contact-message", name: "ContactMessageList", component: ContactMessageList, beforeEnter: guard },
    { path: "/newsletter", name: "Newsletter", component: Newsletter, beforeEnter: guard },
    
    //FAQ
    { path: "/faq", name: "FAQ", component: FAQ, beforeEnter: guard },

    //Social Links
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },

    //Product
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-list", name: "ProductList", component: ProductList, beforeEnter: guard },
    { path: "/brand", name: "BrandList", component: BrandList, beforeEnter: guard },
    { path: "/product-color", name: "ProductColor", component: ProductColor, beforeEnter: guard },
    { path: "/product-offer", name: "ProductOffer", component: ProductOffer, beforeEnter: guard },
    { path: "/purchase-delivery-policy", name: "PurchaseDeliveryPolicy", component: PurchaseDeliveryPolicy, beforeEnter: guard },
    
    { path: "/product-bulk-upload", name: "ProductBulkUpload", component: ProductBulkUpload, beforeEnter: guard },
    { path: "/stock-in-product", name: "ProductStockIn", component: ProductStockIn, beforeEnter: guard },
    
    //Testimonial
    { path: "/testimonial", name: "Testimonial", component: Testimonial, beforeEnter: guard },
    
    //Instagram
    { path: "/home-instagram-section", name: "InstagramService", component: InstagramService, beforeEnter: guard },

    //Tax
    { path: "/sales-tax", name: 'SalesTax', component: SalesTax, beforeEnter: guard },
    { path: "/unapproved-tax-exempt", name: 'UnapprovedTaxExempt', component: UnapprovedTaxExempt, beforeEnter: guard },
    { path: "/approved-tax-exempt", name: 'ApprovedTaxExempt', component: ApprovedTaxExempt, beforeEnter: guard },
    { path: "/rejected-tax-exempt", name: 'RejectedTaxExempt', component: RejectedTaxExempt, beforeEnter: guard },

    //Shipping Settings
    { path: "/ups-settings", name: 'Ups', component: Ups, beforeEnter: guard },
    { path: "/shipping-cms", name: "ShippingCMS", component: ShippingCMS, beforeEnter: guard },
    { path: "/shipping-methods", name: 'ShippingMethods', component: ShippingMethods, beforeEnter: guard },

    //Payment
    { path: "/payment-configuration", name: "PaymentConfiguration", component: PaymentConfiguration, beforeEnter: guard },
    { path: "/payment-list", name: "PaymentList", component: PaymentList, beforeEnter: guard },
    //Coupon
    { path: "/coupon", name: "Coupon", component: Coupon, beforeEnter: guard },

    //Order
    { path: "/create-order", name: "CreateOrder", component: CreateOrder, beforeEnter: guard },
    { path: "/order-details/:id", name: "OrderDetails", component: OrderDetails, beforeEnter: guard },
    { path: "/order-list", name: "OrderList", component: OrderList, beforeEnter: guard },
    // Component UI routes
    { path: "/home-component-ui", name: "HomeComponentUI", component: HomeComponentUI, beforeEnter: guard },
   
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
