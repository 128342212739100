<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Shipping</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Shipping CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Shipping CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-6">
                <div class="border border-3 p-4 rounded">
                  <div class="row">
                    <!-- <div class="col-sm-3 mb-3">
                      <label class="form-label">Fedex Display Name:</label>
                      <input
                        type="text"
                        v-model="pageCMSData.fedexDisplayName"
                        class="form-control"
                        placeholder="Enter title text"
                      />
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label class="form-label">
                        Fedex Logo:
                        <strong>[Preferred Image Size: 70X34, Max Upload Limit: 1MB]</strong>
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        @change="uploadImage"
                        ref="addImage"
                        id="formFile"
                      />
                    </div> -->
                    <!-- <div class="col-sm-3 mb-3">
                      <div class="col-md-12">
                        <img v-if="previewImage1" :src="previewImage1" height="90" width="150" />
                        <img v-else :src="imagePath + pageCMSData.fedexImage" height="90" width="150" />
                      </div>
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-sm-12 mb-3">
                      <label class="form-label">Ups Display Name:</label>
                      <input type="text" v-model="pageCMSData.upsDisplayName" class="form-control"
                        placeholder="Enter title text" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <label class="form-label">
                        Ups Logo:
                        <strong>[Preferred Image Size: 70X34, Max Upload Limit: 1MB]</strong>
                      </label>
                      <input class="form-control" type="file" @change="uploadImage2" ref="addImage2" id="formFile" />
                    </div>
                    <div class="col-sm-12 mb-3">
                      <div class="col-md-12">
                        <img v-if="previewImage2" :src="previewImage2" height="90" width="150" />
                        <!-- <img v-else :src="imagePath + pageCMSData.upsImage" height="90"  /> -->
                        <img v-else :src="pageCMSData.upsImage" height="90"  />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-sm-3 mb-3">
                      <label class="form-label">Local Display Name:</label>
                      <input
                        type="text"
                        v-model="pageCMSData.localDisplayName"
                        class="form-control"
                        placeholder="Enter title text"
                      />
                    </div>
                    <div class="col-sm-6 mb-3">
                      <label class="form-label">
                        Local Logo:
                        <strong>[Preferred Image Size: 70X34, Max Upload Limit: 1MB]</strong>
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        @change="uploadImage3"
                        ref="addImage3"
                        id="formFile"
                      />
                    </div>
                    <div class="col-sm-3 mb-3">
                      <div class="col-md-12">
                        <img v-if="previewImage3" :src="previewImage3" height="100"  />
                        <img v-else :src="imagePath + pageCMSData.localImage" height="100" />
                      </div>
                    </div>
                  </div> -->
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/shipping-cms.js"></script>