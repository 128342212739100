<template>
  <div class="page-wrapper">
    <div class="page-content">
      <!-- <div class="row">
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Order</p>
                  <h4 class="my-1">{{ totalOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <img src="/core/assets/svg/1.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Pending</p>
                  <h4 class="my-1">{{ totalPendingOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <img src="/core/assets/svg/track-blue.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Delivered</p>
                  <h4 class="my-1">{{ totalDeliveredOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <img src="/core/assets/svg/track-green.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Cancelled</p>
                  <h4 class="my-1">{{ totalCancelledOrders }}</h4>
                </div>
                <div
                  class="widgets-icons bg-light-success text-success ms-auto"
                >
                  <img src="/core/assets/svg/order-cancel.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0 text-uppercase">Payment List</h6>
        </div>
        <div class="col-6 float-right">
          <a
            role="button"
            class="btn btn-warning btn-sm m-1 mb-md-0"
            style="float: right"
            @click="exportOrders()"
          >
            <i class="fa-solid fa-file-export"></i>
            Export
          </a>
          <a
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#filterModal"
            id="filterTable"
            class="btn btn-success btn-sm m-1 mb-md-0"
            style="float: right"
          >
            <i class="bx bx-filter text-white float-left"></i>
            Filter
          </a>
        </div>
      </div>

      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Order Id</th>
                  <th>Customer Name</th>
                  <th>Total Amount</th>
                  <th>Refund Amount</th>
                  <th>TransID</th>
                  <th>Refund ID</th>
                  <th>Payment Date</th>
                  <th>Trans Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paymentList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      @click="$router.push('/order-details/' + data.invoiceID)"
                    >{{ data.invoiceID }}</a>
                  </td>
                  <td>{{ data.customerName }}</td>
                  <td>{{ data.paymentAmount }}</td>
                  <td>{{ data.refundAmount ? data.refundAmount : 0 }}</td>
                  <td>{{ data.paymentTransID }}</td>
                  <td>{{ data.refundTransID ? data.refundTransID : "" }}</td>
                  <td>{{ this.$filters.formatDate(data.paymentDate) }}</td>
                  <td>{{ data.transStatus }}</td>
                  <td>
                    <div class="d-flex">
                      <button
                        class="btn btn-primary btn-sm"
                        @click="refundItem(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#refundModal"
                        :disabled="data.isGlobalRefunded == true">
                        {{ data.isGlobalRefunded ? "Refunded" : "Refund" }}
                        </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Order Id</th>
                  <th>Customer Name</th>
                  <th>Total Amount</th>
                  <th>Refund Amount</th>
                  <th>TransID</th>
                  <th>Refund ID</th>
                  <th>Payment Date</th>
                  <th>Trans Status</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <!-- modal -->
      <div
        class="modal fade"
        id="refundModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <form @submit.prevent="refund">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Refund</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  id="refundCloseBtn"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput1"
                    class="form-label"
                  >Refund Amount (Only numbers and decimal are allowed)</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="refundAmount"
                    @keyup="checkNumber()"
                    placeholder="Enter refund amount"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Refund</button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- end modal -->

      <!-- filter -->
      <div
        class="modal fade"
        id="filterModal"
        tabindex="-1"
        aria-labelledby="filterModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Filtering</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="filter_form">
                <div class="row mb-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Customer Name:{{ userName }}</label>
                    <Multiselect v-model="filter.userName" :options="userList" :searchable="true" />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Order Id:</label>
                    <Multiselect v-model="filter.orderId" :options="orderList" :searchable="true" />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-sm-12">
                    <label class="form-label">Trans ID:</label>
                    <Multiselect
                      v-model="filter.transId"
                      :options="transIdList"
                      :searchable="true"
                    />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Start Date:</label>
                    <input v-model="filter.startDate" type="date" class="form-control" />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">End Date</label>
                    <input v-model="filter.endDate" type="date" class="form-control" />
                  </div>
                </div>
                <div class="mb-3">
                  <div class="text-center">
                    <button
                      type="submit"
                      @click="clearFilter()"
                      class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1"
                      data-bs-dismiss="modal"
                    >Clear</button>

                    <button
                      type="button"
                      class="btn btn-sm btn-success rounded-pill ps-3 pe-3 m-1"
                      data-bs-dismiss="modal"
                      @click="validateFilter"
                    >Search</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end filter -->
    </div>
  </div>
</template>

<script src='../js/payment-list.js'></script>


<style scoped>
.edit-button {
  background-color: #49abab;
}

.confirm-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>