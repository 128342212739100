export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.contact.contactPageCMS,
            cmsData: {
                id: "",
                pageTitle: "",
                pageDescription: "",
                formTitle: "",
                formDescription: "",
                address: "",
                contact: "",
                email: "",
                buttonText: "",
                mapUrl: "",
            }
        }
    },
    async created() {
        document.title = "Ganja Containers - Contact Us Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                // this.getCoordinates();
                let data = {
                    id: this.cmsData?.id,
                    pageTitle: this.cmsData?.pageTitle,
                    pageDescription: this.cmsData?.pageDescription,
                    formTitle: this.cmsData?.formTitle,
                    formDescription: this.cmsData?.formDescription,
                    address: this.cmsData?.address,
                    contact: this.cmsData?.contact,
                    email: this.cmsData?.email,
                    mapUrl: this.cmsData?.mapUrl,
                    buttonText: this.cmsData?.buttonText,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        // getCoordinates() {
        //     const apiKey = 'AIzaSyBZgG2qAGJQ2XgjaAGPnQM8YG-es0cNV1c'; // Replace with your actual API key
        //     const geocodingEndpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        //         this.cmsData.address
        //     )}&key=${apiKey}`;

        //     this.$axios
        //         .get(geocodingEndpoint)
        //         .then(response => {
        //             const { results } = response.data;

        //             if (results.length > 0) {
        //                 if (this.cmsData) {
        //                     this.cmsData.latitude = results[0].geometry.location.lat;
        //                     this.cmsData.longitude = results[0].geometry.location.lng;
        //                 }
        //             }
        //         })
        //         .catch(error => {
        //             console.error(error);
        //         });
        // }
    }
}