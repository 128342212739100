import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "Instagram Section",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.cms.instagramService,
            singleData: {},
            media_type: "",
            permalink: "",
            media_url: "",
            username: "",
            caption: '',
            previewImage:'',
            updatePreviewImage:''
        }
    },
    async created() {
        document.title = "Ganja Containers - Instagram Section";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        uploadFeaturedImage: function(event) {
            this.media_url = event.target.files[0];
            let input = this.$refs.blockImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateInstagramImage: function(event) {
            this.media_url = event.target.files[0];
            let input = this.$refs.blockUpdateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updatePreviewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addInstagramSection: async function () {
            if (!this.media_type) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter media type!"
                });
            }
            if (!this.permalink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter permalink!"
                });
            }
            if (!this.media_url) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter media url!"
                });
            }
            if (!this.username) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter username!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('media_type', this.media_type);
                formData.append('permalink', this.permalink);
                formData.append('media_url', this.media_url);
                formData.append('username', this.username);
                formData.append('caption', this.caption);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.postDataToBackend(config);
                this.$refs.blockImage.value = null;
                this.previewImage = '';
                this.media_tpye = '';
                this.permalink = '';
                this.media_url = '';
                this.username = '';
                this.caption = '';
                
                await this.getDataList(this.setURL);

                window.$("#instagramServiceAdd")[0].reset();
                window.$("#addModal").modal('hide');
            }
        },
        editInstagramSection: async function (data) {
            this.singleData = data;
        },
        updateInstagramSection: async function () {
            if (!this.singleData.media_type) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter media type!"
                });
            }
            if (!this.singleData.permalink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter permalink!"
                });
            }
            if (!this.singleData.media_url) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter media url!"
                });
            }
            if (!this.singleData.username) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter username!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('media_type', this.singleData.media_type);
                formData.append('permalink', this.singleData.permalink);
                formData.append('media_url', this.media_url ? this.media_url : this.singleData.media_url);
                formData.append('username', this.singleData.username);
                formData.append('caption', this.singleData.caption);
                let config = {
                    method: "PATCH",
                    url: `${this.setURL}/${this.singleData.id}` ,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.updateDataToBackend(config);
                this.$refs.blockUpdateImage.value = null;
                this.singleData = {};
                this.currentImage = '';
                this.updatePreviewImage = '';
                this.previewImage = '';
                await this.getDataList(this.setURL);
                window.$("#editModal").modal('hide');
            }
        },
        deleteInstagramSection: function (id) {
            this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: `${this.setURL}/${id}`,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);

                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                }
            })

        }
    }
}