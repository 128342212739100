import { authHeader } from "../../../auth";

export default {
    name: "ShippingMethod",
    data() {
        return {
            singleSM: {},
            setURL: '',
            serviceName: '',
            serviceType: '',
            price: 0.00,
        }
    },
    async created() {
        document.title = "Ganja Container - Shipping Methods";
        this.setURL = this.$serverURL + this.$api.shippingMethods.requestURL;
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addValidation: function () {
            if (!this.serviceName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter name!"
                })
                return false;
            }
            if (this.price < 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter valid price!"
                })
                return false;
            }
            return true;
        },
        updateValidation: function () {
            if (!this.singleSM.serviceName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter name!"
                })
                return false;
            }
            if (this.singleSM.price < 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter valid price!"
                })
                return false;
            }
            return true;
        },
        addShippingMethod: async function () {
            if (this.addValidation() == true) {
                let data = {
                    serviceName: this.serviceName,
                    serviceType: this.serviceType,
                    price: this.price
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.shippingMethods.requestURL,
                    data: data,
                    headers: {
                        "Authorization":authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.serviceName = '';
                this.serviceType = '';
                this.price = '';
                await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editShippingMethod: async function (data) {
            this.singleSM = data;
        },
        updateShippingMethod: async function () {
            if (this.updateValidation() == true) {
                let data = {
                    serviceName: this.singleSM.serviceName,
                    serviceType: this.singleSM.serviceType,
                    price: this.singleSM.price
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.shippingMethods.requestURL + '/' + this.singleSM.id,
                    data: data,
                    headers: {
                        "Authorization":authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deleteShippingMethod: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.shippingMethods.requestURL + '/' + id,
                            headers: {
                                "Authorization":authHeader()
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}