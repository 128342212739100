<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="pageCMSData.homePageTitle" class="form-control" placeholder="Home page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Shop Page Title:</label>
                                                <input type="text" v-model="pageCMSData.shopPageTitle" class="form-control" placeholder="Product page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Login Page Title:</label>
                                                <input type="text" v-model="pageCMSData.loginPageTitle" class="form-control" placeholder="Product page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Register Page Title:</label>
                                                <input type="text" v-model="pageCMSData.registerPageTitle" class="form-control" placeholder="Product page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Help & Support Page Title:</label>
                                                <input type="text" v-model="pageCMSData.helpAndSupportPageTitle" class="form-control" placeholder="Sale page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Title:</label>
                                                <input type="text" v-model="pageCMSData.blogPageTitle" class="form-control" placeholder="Blog page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Address Page Title:</label>
                                                <input type="text" v-model="pageCMSData.addressPageTitle" class="form-control" placeholder="Franchise page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Tax Exempt Page Title:</label>
                                                <input type="text" v-model="pageCMSData.taxExemptPageTitle" class="form-control" placeholder="Franchise page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Reset Password Page Title:</label>
                                                <input type="text" v-model="pageCMSData.resetPassPageTitle" class="form-control" placeholder="Franchise page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Change Password Page Title:</label>
                                                <input type="text" v-model="pageCMSData.changePassPageTitle" class="form-control" placeholder="Franchise page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Forget Password Page Title:</label>
                                                <input type="text" v-model="pageCMSData.forgetPassPageTitle" class="form-control" placeholder="Franchise page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Transactions Page Title:</label>
                                                <input type="text" v-model="pageCMSData.transactionsPageTitle" class="form-control" placeholder="Quotation page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Title:</label>
                                                <input type="text" v-model="pageCMSData.aboutPageTitle" class="form-control" placeholder="About page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Order History Page Title:</label>
                                                <input type="text" v-model="pageCMSData.orderHistoryPageTitle" class="form-control" placeholder="Location page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Confirm Order Page Title:</label>
                                                <input type="text" v-model="pageCMSData.confirmOrderPageTitle" class="form-control" placeholder="Location page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Dashboard Page Title:</label>
                                                <input type="text" v-model="pageCMSData.dashboardPageTitle" class="form-control" placeholder="Career page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Account Page Title:</label>
                                                <input type="text" v-model="pageCMSData.accountPageTitle" class="form-control" placeholder="Account page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.termsPageTitle" class="form-control" placeholder="Terms page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Title:</label>
                                                <input type="text" v-model="pageCMSData.cartPageTitle" class="form-control" placeholder="Cart page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Title:</label>
                                                <input type="text" v-model="pageCMSData.wishlistPageTitle" class="form-control" placeholder="Wishlist page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Title:</label>
                                                <input type="text" v-model="pageCMSData.checkoutPageTitle" class="form-control" placeholder="Checkout page title">
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">FAQ Page Title:</label>
                                                <input type="text" v-model="pageCMSData.faqPageTitle" class="form-control" placeholder="Checkout page title">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/page-title-cms.js"></script>