import { authHeader } from '../../../../auth';

export default {
    name: "Purchase Delivery Policy List",
    data() {
        return {
            single: {},
            title: "",
            description: "",
            setURL: this.$serverURL + this.$api.product.purchaseaAndDeliveryPolicy,
            dataList: [],
        }
    },
    async created() {
        document.title = "Ganja Containers - Purchase Delivery Policy List Page";
        this.dataList = await this.getDataList(this.setURL);
        console.log(this.dataList);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addPolicy: async function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
            } else if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
            } else {
                let data = {
                    title: this.title,
                    description: this.description,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        Authorization: authHeader()
                    }

                };
                let response = await this.postData(config);
                if (response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.title = '';
                this.description = '';
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("closeButton").click();
            }
        },
        editPolicy: async function (data) {
            this.single = { ...data };
        },
        updatePolicy: async function () {
            if (!this.single.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
            } else if (!this.single.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
            } else {
                let data = {
                    title: this.single.title,
                    description: this.single.description,
                };
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.single.id,
                    data: data,
                    headers: {
                        Authorization: authHeader()
                    }
                };
                let response = await this.updateData(config);
                if (response.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.single = {};
                this.dataList = await this.getDataList(this.setURL);
                document.getElementById("buttonClose").click();
            }
        },
        deletePolicy: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: {
                                Authorization: authHeader()
                            }
                        }
                        await this.deleteData(config);
                        this.dataList = await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })

        }
    }
}