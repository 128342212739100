<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Header Footer</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Header Footer CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Header Footer CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <label class="form-label">
                                        Logo:
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <img
                                        style="position: relative; left: 11px"
                                        v-if="preview.logo"
                                        :src="preview.logo"
                                        height="70"
                                        />
                                        <img
                                        style="position: relative; left: 11px"
                                        v-else-if="cmsData.logo"
                                        :src="cmsData.logo"
                                        height="70"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 mb-3">
                                    <input
                                    class="form-control mt-3"
                                    type="file"
                                    ref="logo"
                                    accept="image/*"
                                    @change="handleUploadImage('logo','logo','logo', $event)"
                                    />
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <label class="form-label" for="title">Heading:</label>
                                        <input type="text" class="form-control" v-model="cmsData.heading"
                                            id="title" />
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <label class="form-label" for="title">Footer Description:</label>
                                        <input type="text" class="form-control" v-model="cmsData.footerDescription"
                                            id="title" />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label class="form-label" for="title">Copyright Text:</label>
                                        <input type="text" class="form-control" v-model="cmsData.copyrightText"
                                            id="title">
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label class="form-label" for="title">Button Text:</label>
                                        <input type="text" class="form-control" v-model="cmsData.buttonText"
                                            id="title">
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label class="form-label" for="title">Button Link:</label>
                                        <input type="text" class="form-control" v-model="cmsData.buttonLink"
                                            id="title">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="d-grid mt-4">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/header-footer-cms.js"></script>