<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Ups settings</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Ups CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <!-- <h5 class="card-title">Location Page CMS</h5> -->
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-7">
                <div class="border border-3 p-4 rounded">
                  <label class="form-label">Credentials</label>
                  <div class="mb-3">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        v-model="credential"
                        value="sandbox"
                        :checked="isSandbox"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        >SandBox Credential</label
                      >
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        v-model="credential"
                        value="live"
                        :checked="isLive"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        >Live Credential</label
                      >
                    </div>
                  </div>
                  <div v-if="credential == 'sandbox'">
                    <div class="mb-3">
                      <label class="form-label">Access License Number</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxAccessLicenseNumber"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Access license number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">SandBox Url</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxUrl"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Access license number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">User Name</label>
                      <input
                        type="text"
                        v-model="pageCMSData.sandboxUserName"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="User name"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Password</label>
                      <div class="input-group mb-3">
                        <input
                          :type="showPass ? 'text' : 'password'"
                          class="form-control"
                          placeholder="Password"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          v-model="pageCMSData.sandboxPassword"
                        />
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="button-addon2"
                          @click="showPass = !showPass"
                        >
                          <i class="fa-solid fa-eye ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="credential == 'live'">
                    <div class="mb-3">
                      <label class="form-label">Access License Number</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveAccessLicenseNumber"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Access license number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Live Url</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveUrl"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="Access license number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">User Name</label>
                      <input
                        type="text"
                        v-model="pageCMSData.liveUserName"
                        class="form-control"
                        id="inputPageHeaderPageName"
                        placeholder="User name"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Password</label>
                      <div class="input-group mb-3">
                        <input
                          :type="showPass ? 'text' : 'password'"
                          class="form-control"
                          placeholder="Password"
                          aria-label="Recipient's username"
                          aria-describedby="button-addon2"
                          v-model="pageCMSData.livePassword"
                        />
                        <button
                          class="btn btn-primary btn-sm"
                          type="button"
                          id="button-addon2"
                          @click="showPass = !showPass"
                        >
                          <i class="fa-solid fa-eye ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="d-grid">
                    <button
                      v-on:click="update()"
                      type="button"
                      class="btn btn-primary"
                    >
                      Save
                    </button>
                  </div> -->
                </div>
              </div>

              <div class="col-lg-5">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Shipper Account Name</label>
                    <input
                      type="text"
                      v-model="pageCMSData.shipperAccountName"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="name"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Shipper Account Number</label>
                    <input
                      type="text"
                      v-model="pageCMSData.shipperAccountNumber"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Shipper number"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Street Address</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="pageCMSData.addressLine"
                    ></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">City</label>
                    <input
                      type="text"
                      v-model="pageCMSData.city"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="City"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">State Province Code</label>
                    <input
                      type="text"
                      v-model="pageCMSData.stateProvienceCode"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="State code"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Postal Code</label>
                    <input
                      type="text"
                      v-model="pageCMSData.postalCode"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="State code"
                    />
                  </div>
                  <div class="mb-3" hidden>
                    <select
                      v-model="country"
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option style="display: none" selected value="">
                        Select Country
                      </option>
                      <option
                        v-for="(data, index) in countryList"
                        :key="index"
                        :value="{
                          name: data.countryNameEn,
                          code: data.countryCode,
                        }"
                      >
                        {{ data.countryNameEn }}
                      </option>
                    </select>
                  </div>
                  <div class="d-grid">
                    <button
                      v-on:click="update()"
                      type="button"
                      class="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/ups.js"></script>
