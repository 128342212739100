import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.ComponentCms.shippingCmsURL,
            pageCMSData: {
                upsDisplayName: "",
                upsImage: ""
            },
            // imagePath: this.$imageURL + 'shipping-methods/',
            previewImage1: '',
            previewImage2: '',
            previewImage3: '',
            fedexDisplayName: "",
            fedexImage: "",
            upsDisplayName: "",
            upsImage: "",
            localDisplayName: "",
            localImage: "",
        }
    },
    async mounted() {
        document.title = "Ganja Containers - Shipping CMS";
        const pageCMS = await this.getCmsData(this.setURL);
        if (pageCMS.data?.data?.length > 0) {
            this.pageCMSData = pageCMS?.data?.data?.[0];
            console.log(this.pageCMSData)
        }
    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.fedexImage = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage1 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImage2(event) {
            this.pageCMSData.upsImage = event.target.files[0];
            let input = this.$refs.addImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage2 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImage3(event) {
            this.pageCMSData.localImage = event.target.files[0];
            let input = this.$refs.addImage3;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage3 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let formData = new FormData();
                // formData.append('fedexDisplayName', this.pageCMSData.fedexDisplayName);
                formData.append('upsDisplayName', this.pageCMSData.upsDisplayName);
                // formData.append('localDisplayName', this.pageCMSData.localDisplayName);
                // formData.append('fedexImage', this.pageCMSData.fedexImage);
                formData.append('upsImage', this.pageCMSData.upsImage);
                // formData.append('localImage', this.pageCMSData.localImage);
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                console.log(config);
                const response = await this.createUpdateCMS(this.setURL, formData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                this.previewImage2 = '';
                this.previewImage1 = '';
                this.previewImage3 = '';
                this.fedexDisplayName = "";
                this.fedexImage = "";
                this.upsDisplayName = "";
                this.upsImage = "";
                this.localDisplayName = "";
                this.localImage = "";
                // this.$refs.addImage.value = ""
                this.$refs.addImage2.value = ""
                // this.$refs.addImage3.value = ""
                const pageCMS = await this.getCmsData(this.setURL);
                if (pageCMS.data?.data?.length > 0) {
                    this.pageCMSData = pageCMS?.data?.data?.[0];
                    console.log(this.pageCMSData)
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}