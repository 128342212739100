<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="container">
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="invoice-container" id="doc">
                  <div class="invoice-header">
                    <div class="row gutters">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="mb-3 text-center mt-4">
                          <img src="../../../../../public/core/assets/logo.png" width="80"/>
                          <p v-if="pageCMSData">{{ pageCMSData.contactAddress }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                    <!-- Row start -->
                    <div class="row gutters">
                      <div
                        class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                        id="contnet"
                        v-if="
                        billingAddress &&
                        Object.values(billingAddress).length > 0
                      "
                      >
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Billing Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Name:</span>
                              {{
                              billingAddress?.name
                              ? billingAddress?.name
                              : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Contact:</span>
                              {{
                              billingAddress?.mobile
                              ? billingAddress?.mobile
                              : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Email:</span>
                              {{
                              billingAddress?.email
                              ? billingAddress?.email
                              : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <div v-if="billingAddress">
                                <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                {{
                                billingAddress?.address
                                ? billingAddress?.address
                                : ""
                                }}
                                {{
                                billingAddress?.city
                                ? ", " + billingAddress?.city
                                : ""
                                }}
                                {{
                                billingAddress?.postCode
                                ? ", " + billingAddress?.postCode
                                : ""
                                }}
                                {{
                                shippingAddress?.country
                                ? ", " + shippingAddress?.country
                                : ""
                                }}
                              </div>
                              <div v-else>
                                <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                Not available
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Shipping Information</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Name:</span>
                              {{
                              shippingAddress?.name
                              ? shippingAddress?.name
                              : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Contact:</span>
                              {{
                              shippingAddress?.mobile
                              ? shippingAddress?.mobile
                              : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Email:</span>
                              {{
                              shippingAddress?.email
                              ? shippingAddress?.email
                              : "Not available"
                              }}
                            </li>
                            <li class="text-muted">
                              <div v-if="shippingAddress">
                                <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                                <span class="fw-bold">Address:</span>
                                {{
                                shippingAddress?.addressOne
                                ? shippingAddress?.addressOne
                                : ""
                                }}
                                {{
                                shippingAddress?.city
                                ? ", " + shippingAddress?.city
                                : ""
                                }}
                                {{
                                shippingAddress?.postCode
                                ? ", " + shippingAddress?.postCode
                                : ""
                                }}
                                {{
                                shippingAddress?.country
                                ? ", " + shippingAddress?.country
                                : ""
                                }}
                              </div>
                              <div v-else>
                                <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                                <span>Address:</span>
                                Not available
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div class="invoice-details">
                          <p class="text-muted">
                            <strong>Invoice</strong>
                          </p>
                          <ul class="list-unstyled">
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">ID:</span>
                              #{{
                              orderDetails.orderId
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Order Tracking No:</span>
                              <span
                                class="trackingMod"
                                data-bs-toggle="modal"
                                data-bs-target="#addModal"
                              >{{orderDetails.trackingNumber?' '+orderDetails.trackingNumber:' Not Found'}}</span>
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="fw-bold">Creation Date:</span>
                              {{
                              this.$filters.dateFormat(orderDetails.createdAt)
                              }}
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Payment Status:</span>
                              <span
                                :class="[
                                orderDetails.paymentStatus == 'PENDING'
                                  ? 'badge bg-warning'
                                  : orderDetails.paymentStatus == 'Paid'
                                    ? 'badge bg-success'
                                    : 'badge bg-danger',
                                'text-black fw-bold', 'text-white'
                              ]"
                              >{{ orderDetails.paymentStatus }}</span>
                            </li>
                            <li class="text-muted">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Delivery Status:</span>
                              <span
                                :class="[
                                orderDetails.status == 'PENDING'
                                  ? 'badge bg-primary'
                                  : orderDetails.status == 'PROCESSING'
                                    ? 'badge bg-info'
                                    : orderDetails.status == 'SHIPPED'
                                      ? 'badge bg-warning'
                                      : orderDetails.status == 'DELIVERED'
                                        ? 'badge bg-success'
                                        : 'badge bg-danger',
                              ]"
                              >{{ orderDetails.status }}</span>
                            </li>
                            <li class="text-muted" v-if="orderDetails.isGlobalRefunded">
                              <i class="fas fa-circle me-1" style="color: #84b0ca"></i>
                              <span class="me-1 fw-bold">Refund Status:</span>
                              <span :class="['badge bg-primary']">REFUNDED</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                  <div class="invoice-body">
                    <!-- Row start -->
                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Amount</th>
                                <th scope="col" data-html2canvas-ignore="true">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in orderDetails.orders" :key="index">
                                <td scope="row">{{ index + 1 }}</td>
                                <td>
                                   {{ data.name }}
                                  <br />
                                  <p
                                    v-if="data.returnQuantity>0 && (data.returnStatus == 'Approved' || data.returnStatus == 'Unapproved')"
                                  >
                                    <span style="color: red">*</span>
                                    {{`${data.returnQuantity} Item has been` + (data.returnStatus == 'Approved'? ` returned (${data.returnStatus})` : ` requested for return (${data.returnStatus})`)}}
                                  </p>
                                </td>
                                <td>{{ data.quantity }}</td>
                                <td>${{ data.itemPrice }}</td>
                                <td>${{ data.itemSubTotal }}</td>
                                <td data-html2canvas-ignore="true">
                                  <!-- <div class="d-flex">
                                    <button
                                      class="btn btn-outline-primary btn-sm"
                                      @click="refund(data)"
                                      data-bs-toggle="modal"
                                      data-bs-target="#refundModal"
                                      :disabled="data.isRefunded || orderDetails.isGlobalRefunded == true"
                                    >
                                      {{ data.isRefunded || orderDetails.isGlobalRefunded == true ? "Refunded" : "Refund" }}
                                    </button>
                                  </div>-->
                                  <div class="btn-group">
                                    <button
                                      type="button"
                                      :disabled="orderDetails.paymentStatus=='PENDING'"
                                      class="btn btn-outline-primary"
                                    >Actions</button>
                                    <button
                                      type="button"
                                      class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      :disabled="orderDetails.paymentStatus=='PENDING'"
                                    >
                                      <span class="visually-hidden">Toggle Dropdown</span>
                                    </button>
                                    <ul class="dropdown-menu">
                                      <!-- <li>
                                        <button
                                          class="dropdown-item"
                                          @click="refund(data)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#refundModal"
                                          :disabled="data.isRefunded || data.returnQuantity==data.quantity || orderDetails.isGlobalRefunded == true"
                                        >{{ data.isRefunded || (data.returnQuantity==data.quantity && data.returnStatus=='Approved') || orderDetails.isGlobalRefunded == true ? "Refunded" : "Refund" }}</button>
                                      </li>-->
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          data-bs-toggle="modal"
                                          data-bs-target="#returnModal"
                                          @click="handleReturn(data)"
                                          :disabled="data.quantity <= data.returnQuantity || orderDetails.isGlobalRefunded == true"
                                        >{{(data.returnQuantity==data.quantity && data.returnStatus=='Approved')?'Returned & Refunded':'Return & Refund'}}</button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="3">&nbsp;</td>
                                <td colspan="1">
                                  <p>
                                    Subtotal
                                    <br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p
                                    v-if="orderDetails.totalDiscount && orderDetails.totalDiscount != 0"
                                  >
                                    Total Discount
                                    <br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p v-if="orderDetails.salesTaxRate">
                                    Sales Tax({{orderDetails.salesTaxRate}}%)
                                    <br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p>
                                    Shipping Cost
                                    <br />
                                  </p>
                                  <p v-if="orderDetails.couponDiscount != '0'">
                                    Coupon discount
                                    <br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <p v-if="totalRefunded>0">
                                    Total Refunded
                                    <br />
                                    <!-- Shipping &amp; Handling<br /> -->
                                    <!-- Tax<br /> -->
                                  </p>
                                  <!-- <p
                                    v-else-if="
                                    orderDetails.subTotal !=
                                    orderDetails.total
                                  "
                                  >Discount</p>-->
                                  <h5 class="text-success">
                                    <strong>Grand Total</strong>
                                  </h5>
                                </td>
                                <td>
                                  <p>
                                    ${{ parseFloat(orderDetails.subTotal - (orderDetails.totalDiscount ? parseFloat(orderDetails.totalDiscount) : 0)).toFixed(2) }}
                                    <br />
                                    <!-- $100.00<br /> -->
                                    <!-- $49.00<br /> -->
                                  </p>
                                  <p
                                    v-if="orderDetails.totalDiscount && orderDetails.totalDiscount != 0"
                                  >
                                    ${{orderDetails.totalDiscount}}
                                    <br />
                                  </p>
                                  <p v-if="orderDetails.salesTaxRate">
                                    ${{orderDetails.salesTaxAmount}}
                                    <br />
                                  </p>
                                  <p>
                                    ${{ orderDetails.shippingCost }}
                                    <br />
                                  </p>
                                  <p v-if="orderDetails.couponDiscount != '0'">
                                    - ${{ orderDetails.couponDiscount }}
                                    <br />
                                    <!-- $100.00<br /> -->
                                    <!-- $49.00<br /> -->
                                  </p>
                                  <p v-if="totalRefunded>0">
                                    - ${{ totalRefunded }}
                                    <br />
                                    <!-- $100.00<br /> -->
                                    <!-- $49.00<br /> -->
                                  </p>
                                  <!-- <p
                                    v-else-if="
                                    orderDetails.subTotal !=
                                    orderDetails.total
                                  "
                                  >
                                    -${{
                                    orderDetails.subTotal - orderDetails.total
                                    }}
                                  </p>-->
                                  <h5 class="text-success">
                                    <!-- <strong>
                                      ${{
                                      orderDetails.couponDiscount
                                      ? ((parseFloat(orderDetails.total) + parseFloat(orderDetails.shippingCost)) -
                                      parseInt(orderDetails.couponDiscount)).toFixed(2)
                                      : (parseFloat(orderDetails.total) + parseFloat(orderDetails.shippingCost)).toFixed(2)
                                      }}
                                    </strong>-->
                                    <strong>${{getOrderTotal(orderDetails)}}</strong>
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gutters">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="custom-actions-btns mb-5">
              <!-- <a href="#" class="btn btn-primary">
                            <i class="icon-download"></i> Download
              </a>-->
              <a href="javascript:void(0)" class="btn btn-secondary" @click="generatePDF">
                <i class="icon-printer"></i> Print
              </a>
            </div>
          </div>
        </div>

        <!-- return request list  -->

        <div class="row gutters" v-if="returnProductList.length>0">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body p-0">
                <div class="btn-group" style="margin:15px" v-if="bulkUpdate">
                  <button type="button" class="btn btn-outline-info btn-sm mb-md-0">Bulk Action</button>
                  <button
                    type="button"
                    class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" style>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="bulkStatusUpdate('Approved')"
                      >Approve</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="bulkStatusUpdate('Rejected')">Reject</a>
                    </li>
                  </ul>
                </div>
                <div class="invoice-container">
                  <div class="invoice-body">
                    <!-- Row start -->

                    <div class="row gutters">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="table-responsive">
                          <table class="table custom-table m-0">
                            <thead>
                              <tr>
                                <th v-if="bulkUpdate">
                                  <input
                                    class="form-check-input"
                                    v-model="selectAll"
                                    @change="handleSelectAll"
                                    type="checkbox"
                                    value
                                    id="flexCheckDefault"
                                  />
                                </th>
                                <th>SL</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Item Price</th>
                                <th>Total Price</th>
                                <th>Return Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in returnProductList" :key="index">
                                <td v-if="bulkUpdate">
                                  <input
                                    v-if="data.status=='Unapproved'"
                                    class="form-check-input"
                                    v-model="selected[index]"
                                    @change="
                                      () => {
                                        handleSelect(
                                          index,
                                          selected[index],
                                          data
                                        );
                                      }
                                    "
                                    type="checkbox"
                                    id="flexCheckDefault"
                                  />
                                </td>
                                <td>{{index+1}}</td>
                                <td>{{data.productName}}</td>
                                <td>{{ data.quantity }}</td>
                                <td>{{ data.actualPrice }}</td>
                                <td>{{ data.totalPrice }}</td>
                                <td>{{ data.status }}</td>
                                <td>
                                  <div class="d-flex order-actions">
                                    <div
                                      class="d-flex order-actions"
                                      v-if="data.status == 'Unapproved'"
                                    >
                                      <div class="btn-group" role="group">
                                        <button
                                          id="btnGroupDrop1"
                                          type="button"
                                          class="btn btn-outline-primary dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                        >Actions</button>
                                        <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                          <li>
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              data-bs-toggle="modal"
                                              data-bs-target="#noteModal"
                                              @click="viewNote(data)"
                                            >Reason</a>
                                          </li>
                                          <li>
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              @click="updateStatus(data, 'Approved')"
                                            >Approve</a>
                                          </li>
                                          <li>
                                            <a
                                              @click="updateStatus(data, 'Rejected')"
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                            >Reject</a>
                                          </li>
                                        </ul>
                                      </div>

                                      <!-- <button
                                        class="me-2 btn btn-success"
                                        data-bs-toggle="modal"
                                        data-bs-target="#noteModal"
                                        @click="viewNote(data)"
                                      >
                                        <i class="fa-solid fa-file-circle-check text-white"></i>
                                      </button>
                                      <button
                                        href="javascript:void(0)"
                                        class="me-2 btn btn-success"
                                        @click="updateStatus(data, 'Approved')"
                                      >
                                        <i class="bx bx-check text-white"></i>
                                      </button>
                                      <button
                                        href="javascript:void(0)"
                                        class="me-2 btn btn-danger"
                                        @click="updateStatus(data, 'Rejected')"
                                      >
                                        <i class="bx bxs-trash text-white"></i>
                                      </button>-->
                                    </div>
                                    <button
                                      v-else
                                      type="button"
                                      :class="['me-2', 'btn', data.status == 'Approved' ? 'btn-outline-success':'btn-outline-danger']"
                                      data-bs-toggle="modal"
                                      data-bs-target="#noteModal"
                                      :disabled="data.status == 'Approved' || data.status == 'Rejected'"
                                      @click="viewNote(data)"
                                    >{{ data.status == 'Approved' ? 'Approved' : data.status == 'Rejected' ? 'Rejected' : ''}}</button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Row end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Add Modal -->
        <div
          class="modal fade"
          id="addModal"
          tabindex="-1"
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <form @submit.prevent="addorUpdateOrderTracking" enctype="multipart/form-data">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="addModalLabel">Add Tracking Number</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <label class="form-label">Tracking Number:</label>
                  <input
                    class="form-control mb-1"
                    type="text"
                    v-model="trackingNumber"
                    placeholder="Enter Tracking Number"
                    aria-label="default input example"
                  />
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Add Modal -->

        <!-- note modal -->
        <div
          class="modal fade"
          id="noteModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Return Reason</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">{{note ? note : 'Not Available!'}}</div>
            </div>
          </div>
        </div>
        <!-- note modal -->

        <div
          class="modal fade mt-5"
          id="returnModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Return Product</h5>
                <button
                  type="button"
                  class="btn-close"
                  id="return-close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Reason</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="width: 15% !important;">
                          <div class="itemside" style="display:flex; align-items: center;">
                            <div class="left m-1">
                              <img
                                :src="imagePath + singleReturnProduct.featuredImage"
                                width="40"
                                height="40"
                                class="img-xs"
                                alt="Item"
                              />
                            </div>
                            <div class="info m-1">{{ singleReturnProduct.name }}</div>
                          </div>
                        </td>
                        <td>
                          <textarea
                            class="form-control m-1"
                            id="exampleFormControlTextarea1"
                            v-model="singleReturnProduct.note"
                            rows="1"
                          ></textarea>
                        </td>
                        <td>
                          <div class="numbers-row m-1" style="display:flex;">
                            <input
                              type="text"
                              :value="singleReturnProduct.insertQty"
                              id="quantity_1"
                              class="qty2"
                              name="quantity_1"
                            />
                            <button
                              :disabled="singleReturnProduct.remainingQty == 0"
                              style="border: none"
                              class="inc button_inc"
                              @click="increaseQuantity()"
                            >+</button>
                            <button
                              :disabled="singleReturnProduct.remainingQty == 0"
                              style="border: none"
                              class="dec button_inc"
                              @click="decreaseQuantity()"
                            >-</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  style="color:black"
                >Close</button>
                <button type="button" class="btn btn-primary" @click="confirmReturn(singleReturnProduct.itemPrice*singleReturnProduct.insertQty)">Confirm Return</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/order-details.js"></script>

<style scoped>
@import "../css/order-details.css";
.trackingMod {
  color: black;
  border-bottom: 1px #09f dashed;
  cursor: pointer;
  text-decoration: none;
}
.trackingMod:hover {
  color: #09f;
  border-bottom: 1px #09f dashed;
  cursor: pointer;
  font-weight: bolder;
}
.order-actions a {
  font-size: 18px;
  width: 170px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeecec;
  text-align: center;
  border-radius: 0%;
  color: #2b2a2a;
  margin: 5px;
}

.numbers-row {
  position: relative;
  width: 100%;
  height: 40px;
  overflow: visible;
  border: 1px solid #dddddd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #fff;
  text-align: left !important;
}

input.qty2 {
  position: relative;
  width: 40px;
  height: 40px;
  text-align: center;
  left: 50%;
  background: none;
  padding: 5px;
  border: none;
  margin-left: -20px;
}
input.qty2:focus {
  outline: none;
  box-shadow: none;
}

.button_inc {
  cursor: pointer;
  position: absolute;
  width: 33px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  z-index: 2;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 300 !important;
  color: #999;
}
.button_inc:hover {
  color: #004dda;
}

.dec {
  left: 0;
  top: 0;
}

.inc {
  right: 0;
  top: 0;
}
</style>
