import { authHeader } from "../../../../auth";
import * as XLSX from 'xlsx';
import { countries } from "../../../../../config/country";
import { states } from '../../../../../config/states-list';
export default {
    data() {
        return {
            states: states,
            orderList: [],
            countries: countries,
            deliveryStatus: "",
            totalOrders: "",
            totalPendingOrders: "",
            totalDeliveredOrders: "",
            totalCancelledOrders: "",
            filter: {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            data: {
                paymentStatus: "PENDING"
            },
            userList: [],
            filteredOrderIdList: [],
            setURL: this.$serverURL + this.$api.user.customerUserList,
            sheets: [],
            countryList: [],
            pickUpAddress: "",
            pickUpCity: "",
            pickUpStateCode: "",
            pickUpPostCode: "",
            pickUpCountry: {
                name: 'United States of America',
                code: 'US',
            },
            pickUpRate: "",
            trackingNumber:'',
            trackingOrderID: '',
            labelStockType: '',
            labelImageType: '',
            orderTableID: ''            
        }
    },
    async mounted() {
        document.title = "Ganja Containers -Order List";
        await this.getOrderList();
        await this.getCustomerList();
        this.countryList = this.countries.map(el => {
            return {value:{ name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn}
        });
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getOrderList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderListURL + "?paymentStatus=" + this.filter.paymentStatus + "&deliveryStatus=" + this.filter.deliveryStatus + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate + "&customerName=" + this.filter.userName + "&orderId=" + this.filter.orderId,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.data;
                        if (this.filteredOrderIdList.length == 0) {
                            this.filteredOrderIdList = this.orderList.map(el => {
                                return { value: el.orderId, label: el.orderId }
                            });
                        }
                        this.totalOrders = response.data.data.totalOrders
                        this.totalPendingOrders = response.data.data.totalPendingOrders
                        this.totalDeliveredOrders = response.data.data.totalDeliveredOrders
                        this.totalCancelledOrders = response.data.data.totalCancelledOrders
                    }
                   
                }).catch(error => {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                   
                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el.firstName + el.lastName, label: el.firstName + el.lastName }
                    });
                }
                else {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        setAddress(locationData) {
            let location = locationData.formatted_address;
            let addressComponent = locationData.address_components;
            let locationSplit = location.split(',');
            if (locationSplit[0].trim() != locationData.name) {
                location = locationData.name + ', ' + location;
            }
            this.pickUpAddress = locationData.name;

            this.pickUpCity = "";
            this.pickUpStateCode = "";
            this.pickUpPostCode = "";

            for (let i = 0; i < addressComponent.length; i++) {
                if (addressComponent[i].types[0] == "locality") {
                    this.pickUpCity = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "postal_code") {
                    this.pickUpPostCode = addressComponent[i].long_name;
                } else if (addressComponent[i].types[0] == "administrative_area_level_1") {
                    this.pickUpStateCode = addressComponent[i].short_name;
                }
            }
        },
        async updateOrder(param) {
            try {
                let data = {
                    status: param.status,
                    paymentStatus: param.paymentStatus,
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.order.orderURL + '/' + param.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                this.$swal.showLoading();
                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200) {
                        this.getOrderList();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            // title: "Good Choice",
                            text: "Order updated successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                }).catch(error => {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        confirmShippment: async function(id){
            try {
                let data = {
                    id: id
                };
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.order.confirmOrder,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                this.$swal.showLoading();
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200) {
                        window.$('#example').DataTable().destroy();
                        await this.getOrderList();
                        window.$('#example').DataTable();
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            // title: "Good Choice",
                            text: "Order shipped successfully.",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                   
                }).catch(error => {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        checkPickUpRate: async function(){
            let pickupAddress = {
                addressOne: this.pickUpAddress,
                city: this.pickUpCity,
                stateCode: this.pickUpStateCode,
                postCode: this.pickUpPostCode,
                countryCode: this.pickUpCountry.code,
            };
            let data = {
                pickupAddress: pickupAddress
            }
            let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.checkPickUpRate,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                this.$swal.close();
                if (response.status == 200 && response.data.price) {
                    this.pickUpRate = response.data.price;
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Pick up rate not available"
                    });
                }

            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.close();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async clearFilter() {
            this.filter = {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        async clearPickUp() {
            this.pickUpAddress = "",
            this.pickUpCity = "",
            this.pickUpStateCode = "",
            this.pickUpPostCode = "",
            this.pickUpRate= "",
            document.getElementById("closePickUp").click();
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        exportOrders: function () {
            this.orderList.map((el) => {
                let shippingInfo = JSON.parse(el.shippingAddress);
                this.sheets.push({
                    'Id': el.id,
                    'OrderId': el.orderId,
                    "Customer Name": el.customerName,
                    "Mobile": shippingInfo.mobile,
                    "Email": shippingInfo.email,
                    "Address": shippingInfo.address1,
                    "City": shippingInfo.city,
                    "Post Code": shippingInfo.postCode,
                    "Country": shippingInfo.country,
                    'Total Price': el.total,
                    'Total Quantity': el.totalQuantity,
                    'Order Date': this.$filters.formatDate(el.createdAt),
                    'Payment Method': el.paymentMethodName,
                    'Payment Status': el.paymentStatus,
                    'Shipping Status': el.status,
                    'Shipping Label': el.labelImageEncode,
                    'Tracking Number': el.trackingNumber,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'order_report(' + date + ').xlsx')
        },
        async deleteOrder(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.order.orderURL + '/' + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.$axios(config).then((response) => {
                            if (response.data.deleted == true) {
                                this.getOrderList();
                                this.$swal.fire({
                                    icon: "success",
                                    text: "Order deleted Successfully."
                                });
                            }
                           
                        }).catch(error => {
                           
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong. Please try again!" + error
                            });
                        });
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },
        async addorUpdateOrderTracking() {
            try {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "This will reflect to the customer dashboard aswell.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Update order info'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let data = {
                            trackingNumber: this.trackingNumber,
                            id: this.trackingOrderID
                        }
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.order.updateOrAddTrackingNo,
                            data: data,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        };
                        this.$swal.showLoading();
                        await this.$axios(config).then(async (response) => {
                            this.$swal.hideLoading();
                            if (response.status == 200) {
                                console.log(response.data.message)
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                                await this.getOrderList();
                                //document.getElementById('refundCloseBtn').click();
                            }
                        }).catch(error => {
                            this.$swal.hideLoading();
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async updateModalValue(id, trackingNumber){
            this.trackingOrderID = id;
            this.trackingNumber = trackingNumber;
        },

        async addorUpdateShippingLabel(id) {
            this.orderTableID = id;
            try {
                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "This will reflect to the customer dashboard aswell.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Confirm!'
                }).then(async (result) => {
                    
                    if (result.isConfirmed) {
                        // let data = {
                        //     labelStockType: this.labelStockType,
                        //     labelImageType: this.labelImageType
                        // }
                        this.$swal.showLoading();
                        let data = {
                            labelStockType: "Na",
                            labelImageType: "Na"
                        }
                        let config = {
                            method: "PATCH",
                            url: this.$serverURL + this.$api.order.ordersShippingLabelURL + this.orderTableID ,
                            data: data,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        };
                        this.$swal.showLoading();
                        await this.$axios(config).then(async (response) => {
                            this.$swal.hideLoading();
                            if (response.status == 200) {
                                console.log(response.data.message)
                                this.$swal.fire({
                                    toast: true,
                                    position: 'top-end',
                                    text: response.data.message,
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    animation: false,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                    }
                                });
                                await this.getOrderList();
                                this.$swal.hideLoading();
                                //document.getElementById('refundCloseBtn').click();
                            }
                        }).catch(error => {
                            this.$swal.hideLoading();
                            this.$swal.fire({
                                icon: "error",
                                text: error.response.data.message
                            });
                        });
                    }
                })
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later."
                });
            }
        },
        async updateShippingLabelModalValue(id, labelStockType, labelImageType) {
            this.orderTableID = id;
            this.labelStockType = labelStockType;
            this.labelImageType = labelImageType;
        }
    }

}