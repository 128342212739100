<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img src="../../../../public/core/assets/logo.png" class="logo-icon" alt="logo icon" />
      </div>
      <div>
        <h4 class="logo-text" style="font-size: 18px">Ganja Containers</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bxs-dashboard"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-data"></i>
          </div>
          <div class="menu-title">Data</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/contact-message')">
              <div class="parent-icon">
                <i class="bx bxs-message"></i>
              </div>
              <div class="menu-title">Contact</div>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/newsletter')">
              <div class="parent-icon">
                <i class="bx bxs-news"></i>
              </div>
              <div class="menu-title">Newsletter</div>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/testimonial')">
              <div class="parent-icon">
                <i class="bx bxs-book-reader"></i>
              </div>
              <div class="menu-title">Testimonial</div>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="bx bxs-user"></i>
          </div>
          <div class="menu-title">Users</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-cart"></i>
          </div>
          <div class="menu-title">POS / Orders</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bxs-package"></i>
              </div>
              <div class="menu-title">Product</div>
            </a>
            <ul>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/brand')">
                  <i class="bx bx-right-arrow-alt"></i>Brands
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-list')">
                  <i class="bx bx-right-arrow-alt"></i>Products
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-color')">
                  <i class="bx bx-right-arrow-alt"></i>Product Color
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-offer')">
                  <i class="bx bx-right-arrow-alt"></i>Product Offer
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bxs-folder-minus"></i>
              </div>
              <div class="menu-title">Tax</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/sales-tax')">
                  <i class="bx bx-right-arrow-alt"></i>Sales Tax
                </a>
              </li>
              <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="fa-solid fa-circle-minus"></i>
              </div>
              <div class="menu-title">Tax Exempt</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/unapproved-tax-exempt')">
                  <i class="bx bx-right-arrow-alt"></i>Unapproved
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/approved-tax-exempt')">
                  <i class="bx bx-right-arrow-alt"></i>Approved
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/rejected-tax-exempt')">
                  <i class="bx bx-right-arrow-alt"></i>Rejected
                </a>
              </li>
            </ul>
          </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bxs-cart"></i>
              </div>
              <div class="menu-title">Order</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/create-order')">
                  <i class="bx bx-right-arrow-alt"></i>Create Order
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/order-list')">
                  <i class="bx bx-right-arrow-alt"></i>Order List
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/coupon')">
              <div class="parent-icon">
                <i class="bx bxs-coupon"></i>
              </div>
              <div class="menu-title">Coupon</div>
            </a>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bxs-credit-card"></i>
              </div>
              <div class="menu-title">Payment</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/payment-configuration')">
                  <i class="bx bx-right-arrow-alt"></i>Payment Credential
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/payment-list')">
                  <i class="bx bx-right-arrow-alt"></i>Payment List
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bxs-truck"></i>
              </div>
              <div class="menu-title">Shipping Settings</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/ups-settings')">
                  <i class="bx bx-right-arrow-alt"></i>Ups Settings
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/shipping-methods')">
                  <i class="bx bx-right-arrow-alt"></i>Local Shipping Methods
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/shipping-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Shipping CMS
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="bx bxs-cog"></i>
          </div>
          <div class="menu-title">CMS Settings</div>
        </a>
        <ul>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="bx bx-log-in-circle"></i>
              </div>
              <div class="menu-title">Auth</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/login-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Login Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/register-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Register Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/reset-password-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Reset Password Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bx-home-alt"></i>
              </div>
              Home
            </a>
            <ul>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/hero-section-slider')">
                  <i class="bx bx-right-arrow-alt"></i>Hero Section Slider
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-about-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>About Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-about-section')">
                  <i class="bx bx-right-arrow-alt"></i>About Section
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-banner-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Banner Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-our-product-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Our Product Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-discover-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Discover Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/package')">
                  <i class="bx bx-right-arrow-alt"></i>Packages
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-package-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Package Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-promotion-section')">
                  <i class="bx bx-right-arrow-alt"></i>Promotion Section
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-blog-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-testimonial-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Testimonial Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-instagram-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Instagram Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/home-instagram-section')">
                  <i class="bx bx-right-arrow-alt"></i>Instagram Service
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bx-copy-alt"></i>
              </div>
              Header Footer
            </a>
            <ul>
              <li>
                <a href="/header-banner-cms" @click.prevent="$router.push('/header-footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Footer CMS
                </a>
              </li>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/footer-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer Banner CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bx-package"></i>
              </div>
              Product
            </a>
            <ul>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/purchase-and-delivery-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Purchase and Delivery
                  Policy CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/purchase-delivery-policy')">
                  <i class="bx bx-right-arrow-alt"></i>Purchase and Delivery
                  Policy
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/refund-policy-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Refund Policy CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="bx bx-error-circle"></i>
              </div>
              About Us
            </a>
            <ul>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-one-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section One CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/about-us-section-two-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/our-mission-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Our Mission Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/our-community-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Our Community Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="
                  $router.push('/open-source-community-section-cms')
                  ">
                  <i class="bx bx-right-arrow-alt"></i>Open source Community
                  Section CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="fa-solid fa-question"></i>
              </div>
              FAQ
            </a>
            <ul>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/faq')">
                  <i class="bx bx-right-arrow-alt"></i>Q & A
                </a>
              </li>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/faq-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>FAQ Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/page-title-cms')">
              <i class='bx bx-notepad'></i> Page Title CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-post')">
              <i class="fa-solid fa-blog"></i>Blog Post
            </a>
          </li>
          <li>
            <a href="/contact-us-cms" @click.prevent="$router.push('/contact-us-cms')">
              <i class="bx bx-right-arrow-alt"></i>Contact Us Page CMS
            </a>
          </li>
          <li>
            <a href="/contact-us-cms" @click.prevent="$router.push('/social-links')">
              <i class="bx bx-right-arrow-alt"></i>Social Links
            </a>
          </li>
          <li>
            <a href="/privacy-page-cms" @click.prevent="$router.push('/privacy-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page CMS
            </a>
          </li>
          <li>
            <a href="/refund-page-cms" @click.prevent="$router.push('/terms-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Terms & Condition Page CMS
            </a>
          </li>
        </ul>
      </li>

      <!-- component ui -->
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="bx bxs-cog"></i>
          </div>
          <div class="menu-title">Component UI</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/home-component-ui')">
              <i class="bx bx-right-arrow-alt"></i>Home Page
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>