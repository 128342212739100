<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Sales Tax</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="bx bx-home-alt"></i>
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Sales Tax Settings</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Global Sales Tax</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <form @submit.prevent="updateCms">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="mb-3">
                                            <label class="form-label">Tax Title:</label>
                                            <input type="text" class="form-control" v-model="pageCMSData.name" placeholder="Enter title"/>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Tax Amount:</label>
                                            <div class="input-group mb-3">
                                                <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="pageCMSData.salesTax"/>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-grid">
                                            <button type="submit" class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="card-body">
                    <h5 class="card-title">State Wise Sales Tax</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6" v-if="!isEdit">
                                <form @submit.prevent="addSalesTax">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="mb-3">
                                            <label class="form-label">State Name:</label>
                                            <select v-model="stateName" class="form-select">
                                                <option v-for="(data, index) in states" :key="index" :value="data.name">
                                                    {{ data.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Tax Title:</label>
                                            <input type="text" class="form-control" v-model="taxTitle"/>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Tax Amount:</label>
                                            <div class="input-group mb-3">
                                                <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="taxAmount"/>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-grid">
                                            <button type="submit" class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-6" v-if="isEdit">
                                <form @submit.prevent="updateSalesTax">
                                    <div class="border border-3 p-4 rounded">
                                        <div class="mb-3">
                                            <label class="form-label">State Name:</label>
                                            <select v-model="singleData.stateName" class="form-select">
                                                <option v-for="(data, index) in states" :key="index" :value="data.name">
                                                    {{ data.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Tax Title:</label>
                                            <input type="text" class="form-control" v-model="singleData.taxTitle"/>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Tax Amount:</label>
                                            <div class="input-group mb-3">
                                                <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="singleData.taxAmount"/>
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-grid">
                                            <button type="submit" class="btn btn-primary">Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-6">
                                <div class="table-responsive">
                                    <table id="example" class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>State Name</th>
                                                <th>Title</th>
                                                <th>Tax Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index) in dataList" :key="index">
                                                <td> {{ index + 1 }} </td>
                                                <td> {{ data.stateName }} </td>
                                                <td> {{ data.taxTitle }} </td>
                                                <td> {{ data.taxAmount }} </td>
                                                <td>
                                                    <div class="btn-group">
                                                        <button type="button" class="btn btn-outline-primary">Action</button>
                                                        <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false"><span class="visually-hidden">Toggle Dropdown</span></button>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="dropdown-item" v-on:click="editSalesTax(data)" href="#">Edit</a></li>
                                                            <li><a class="dropdown-item" v-on:click="deleteSalesTax(data.id)" href="#">Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>SL</th>
                                                <th>State Name</th>
                                                <th>Title</th>
                                                <th>Tax Amount</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script src="../js/sales-tax.js"></script>