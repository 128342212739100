<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home">
                                <i class="bx bx-home-alt"></i>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Edit Package</li>
                    </ol>
                </nav>
                <div class="ms-auto"></div>
            </div>
            <hr/>
            <form>
                <div class="card">
                    <div class="card-body p-4">
                        <h5 class="card-title">Edit Package</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="border border-3 p-4 rounded mb-3">
                                        <div class="border border-3 p-4 rounded mb-3">
                                            <div class="col-sm-6 mb-3">
                                                <label for="inputVendor" class="form-label">Product Name:</label>
                                                <select class="form-select" v-model="productName" id="inputVendor">
                                                <option style="display: none" selected value="">
                                                    Select Product
                                                </option>
                                                <option v-for="(data, index) in productList" :key="index" :value="{ id: data.id, name: data.name, urlSlug: data.urlSlug }">
                                                    {{ data.name }}
                                                </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="border border-3 p-4 rounded mb-3">
                                        <div class="row mb-3" v-for="index in counter" :key="index">
                                            <div class="col-sm-6">
                                                <label class="form-label">Quantity:</label>
                                                <input type="text" class="form-control" v-model="specificationKey[index - 1]"/>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="form-label">Price:</label>
                                                <div class="d-flex gap-2">
                                                    <input type="text" class="form-control" v-model="specificationValue[index - 1]"/>
                                                    <button v-if="counter > 1" @click="deleteSpecificationRow(index - 1)" type="button" class="btn btn-danger">X</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <button type="button" @click="addSpecification()" class="btn btn-primary">Add More</button>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="d-grid">
                                            <button type="button" @click="updateEntity()" class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script src='../js/edit-package.js'></script>
  
  